<template>
  <Layout class="InvitationCode">
    <div class="overview">
      <div class="items">
        <div class="num">
          {{ statisticsInfo.cumulativeRegisteredUsers || '-' }}
        </div>
        <div class="name">
          累计注册用户
        </div>
      </div>
      <div class="items">
        <div class="num">
          {{ statisticsInfo.cumulativePayingUsers || '-' }}
        </div>
        <div class="name">
          累计付费用户
        </div>
      </div>
      <div class="items">
        <div class="num">
          {{ (statisticsInfo.payRatio || 0).toFixed(2) + '%' }}
        </div>
        <div class="name">
          付费转化比(%)
        </div>
      </div>
      <div class="items">
        <div class="num">
          {{ (statisticsInfo.cumulativePaymentAmount / DIVISOR.TRUE_AMOUNT) || '-' }}
        </div>
        <div class="name">
          累计付费金额($)
        </div>
      </div>
    </div>
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
      >
        <el-form-item prop="dateRange">
          <el-date-picker
            v-model="formData.dateRange"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="formData.activityName"
            clearable
            placeholder="活动名称"
          />
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="formData.globalStatus"
            placeholder="状态"
            clearable
          >
            <el-option
              v-for="(item, index) in promotionStateList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="formData.channelId"
            placeholder="渠道类型"
            clearable
          >
            <el-option
              v-for="(item, index) in promotionChannelList"
              :key="index"
              :label="item.channelName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <!-- <el-form-item>
          <el-select
            v-model="formData.triggerType"
            placeholder="触发类型"
            clearable
          >
            <el-option
              v-for="(item, index) in triggerModeList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
      <template #right>
        <el-button
          type="primary"
          @click="handleCreatInvitationCode"
        >
          新建邀请码
        </el-button>
        <el-button
          type="primary"
          @click="tochannelManagement"
        >
          渠道管理
        </el-button>
      </template>
    </BHeader>
    <el-table
      ref="table"
      v-loading="listInfo.loading"
      :data="listInfo.list"
      stripe
    >
      <el-table-column type="expand">
        <template #default="{row}">
          <el-form
            :model="row"
            label-position="right"
            class="table-expand"
            label-width="120px"
          >
            <el-row>
              <el-col :span="12">
                <el-row
                  v-for="(item, index) in (row.prizeDetail || [])"
                  :key="index"
                  class="rewardRows"
                >
                  <el-col :span="24">
                    <el-form-item :label="`奖励商品${index + 1}`">
                      <div class="detail">
                        触发方式 · {{ (triggerModeMap[item.triggerMode] || {}).name || '未定义' }}
                      </div>
                      <template
                        v-if="[
                          triggerModeValue.REGISTER_COIN
                        ].includes(item.triggerMode)"
                      >
                        <div class="detail">
                          奖品类型 · {{ (rewardTypetMap[item.prizeType] || {}).name || '未定义' }}
                        </div>
                        <div class="detail">
                          <template v-if="item.prizeType === 1">
                            奖品数量 · {{ (rewardCountMap[item.goodsId] || {}).name || '未知数据' }}
                          </template>
                          <template v-else-if="[11, 12, 13].includes(item.prizeType)">
                            奖品数量 · {{ (item.prizeNum / 10).toFixed(1) }} 折
                          </template>
                          <template v-else>
                            奖品数量 · {{ item.prizeNum || '-' }} Gold
                          </template>
                        </div>
                      </template>
                      <!-- <div
                        v-if="[
                          triggerModeValue.FIRST_CHARGE_COIN
                        ].includes(item.triggerMode)"
                        class="detail"
                      >
                        奖品内容:
                        <br>
                        <el-table
                          border
                          :data="item.giftPrize || []"
                        >
                          <el-table-column label="平台">
                            <template #default="{row: subRow}">
                              {{ (osPlatformMap[subRow.platform] || {}).name || subRow.platform || '/' }}
                            </template>
                          </el-table-column>
                          <el-table-column label="APP">
                            <template #default="{row: subRow}">
                              {{ (appMap[subRow.appId] || {}).name || subRow.appId || '/' }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            align="center"
                            label="目标商品"
                            min-width="120px"
                          >
                            <template #default="{row: subRow}">
                              {{ (allRewardCountMap[subRow.goodsId] || {}).name || subRow.goodsId || '/' }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="prizeNum"
                            align="center"
                            label="附赠时长/金币数量"
                          />
                          <el-table-column
                            prop="prizeCount"
                            label="赠送次数/按月"
                            align="center"
                          />
                        </el-table>
                      </div> -->
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12">
                <el-form-item label="创建时间">
                  {{ dataFomat(row.activityDetail.createTime) }}
                </el-form-item>
                <el-form-item label="渠道名称">
                  {{ row.activityDetail.channelName }}
                </el-form-item>
                <el-form-item label="渠道类型">
                  {{ (promotionChannelMap[row.activityDetail.channelId] || {}).channelName || '未定义' }}
                </el-form-item>
                <el-form-item label="活动时间">
                  {{ dataFomat(row.activityDetail.startUsedTime) }} -- {{ dataFomat(row.activityDetail.endUsedTime) }}
                </el-form-item>
                <el-form-item label="模式">
                  {{ codeTypeMap[row.activityDetail.codeType] || '未知' }}
                </el-form-item>
                <el-form-item
                  v-if="row.activityDetail.codeType=== 2"
                  label="邀请码数量"
                >
                  {{ row.activityDetail.codeNum || '/' }}
                </el-form-item>
                <el-form-item
                  v-if="row.activityDetail.codeType=== 2"
                  label="下载Excel"
                >
                  <el-button
                    type="text"
                    @click="downloadExcel(row)"
                  >
                    link
                  </el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column
        prop="activityDetail.activityName"
        label="兑换活动"
      />
      <el-table-column
        prop="activityDetail.cumulativeRegisteredUsers"
        label="新用户数"
        width="100px"
      />
      <el-table-column
        prop="activityDetail.cumulativePayingUsers"
        label="付费用户数"
        width="120px"
      />
      <el-table-column
        prop="activityDetail.cumulativePaymentAmount"
        label="累计付费金额($)"
        width="140px"
      >
        <template #default="{row}">
          {{ row.activityDetail.cumulativePaymentAmount / DIVISOR.TRUE_AMOUNT }}
        </template>
      </el-table-column>
      <el-table-column
        prop="activityDetail.globalStatus"
        label="活动状态"
      >
        <template #default="{row}">
          {{ (promotionStateMap[row.activityDetail.globalStatus] || {}).name || '未定义' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="activityDetail.inviteCode"
        label="邀请码aaa"
      />
      <el-table-column
        label="操作"
        width="220px"
      >
        <template #default="{row}">
          <BTextButton
            title="数据表现"
            icon="fa fa-line-chart"
            @click="handleViewItem(row)"
          />
          <BTextButton
            title="编辑"
            icon="fa fa-wrench"
            @click="handleEditItem(row)"
          />
          <BTextButton
            :loading="row.loading"
            :title="(promotionStateMap[row.activityDetail.globalStatus] || {}).name || '未定义'"
            :icon="`fa ${row.activityDetail.globalStatus === 2 ? 'fa-check-circle' : 'fa-ban '}`"
            @click="handleToggleItem(row)"
          />
          <BTextButton
            :loading="row.deleteLoading"
            title="删除"
            icon="fa fa-trash-o"
            @click="handleDeleteItem(row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryDataList"
    />
  </Layout>
</template>

<script>
import {
  promotionStateList,
  promotionStateMap,
  triggerModeList,
  triggerModeMap,
  rewardTypetMap,
  osPlatformMap,
  appMap,
  channelList,
  appList
} from '@/utils/selectOptions.js'
import {
  getAllChannelType,
  getInviteActivityList,
  deleteInviteActivity,
  getInviteActivityStatistics,
  updateInviteActivityStatus,
  couponCodeGetGoods,
  downloadInviteCode
} from '@/api/CouponsAndRedeemCode.js'

import {
  DIVISOR,
  APPID,
  GOODS_TYPE,
  CHANNEL,
  EXPERIMENT_GROUP,
  triggerModeValue
} from '@/enum'

const codeTypeMap = {
  1: '通用码',
  2: '1人1码'
}
export default {
  name: 'InvitationCodeNoCache',
  data () {
    return {
      promotionStateList,
      promotionStateMap,
      triggerModeValue,
      promotionChannelList: [],
      promotionChannelMap: {},
      allRewardCountList: [],
      allRewardCountMap: {},
      triggerModeList,
      triggerModeMap,
      rewardTypetMap,
      osPlatformMap,
      appMap,
      codeTypeMap,
      formData: {
        dateRange: [],
        pageNum: 1,
        pageSize: 20
      },
      listInfo: {
        list: [],
        total: 0,
        loading: false
      },
      statisticsInfo: {},
      DIVISOR,
      rewardCountList: [],
      rewardCountMap: {}
    }
  },
  created () {
    this.queryDataList()
    this.getAllChannelType()
    this.queryInviteActivityStatistics()
    this.queryRewardCountList()
    this.queryAllRewardCountList()
  },
  methods: {
    dataFomat (date) {
      if (date === '0000-00-00 00:00:00' || !date) { return '-' }
      return date
    },
    queryRewardCountList () {
      couponCodeGetGoods({
        appId: APPID.Netboom,
        channel: CHANNEL.Android,
        experimentGroup: EXPERIMENT_GROUP.BASE_GROUP,
        goodsType: GOODS_TYPE.GOLD
      })
        .then(res => {
          if (res.code === 200) {
            const list = res.data.list
            const tempList = []
            list.forEach((item, index) => {
              tempList[index] = {
                name: item.name,
                value: item.goodsId
              }
            })
            this.$set(this, 'rewardCountList', tempList)
            const rewardCountMap = list.reduce((result, item) => {
              result[item.goodsId] = item
              return result
            }, {})
            this.$set(this, 'rewardCountMap', rewardCountMap)
          }
        })
    },
    queryAllRewardCountList () {
      const ENUM_PARAMS = []
      const ENUM_INDEX = []
      channelList.forEach(item => {
        appList.forEach(it => {
          ENUM_PARAMS.push({
            appId: it.value,
            channel: item.value
          })
          ENUM_INDEX.push(`${item.name.toUpperCase()}_${it.value}`)
        })
      })
      Promise.all(
        ENUM_PARAMS.map(item => {
          return couponCodeGetGoods({
            ...item,
            experimentGroup: EXPERIMENT_GROUP.BASE_GROUP,
            goodsType: GOODS_TYPE.bothVipAndGold
          })
        })
      ).then(resList => {
        const allRewardCountMap = {}
        resList.forEach((item) => {
          item.data.list.forEach(it => {
            allRewardCountMap[it.goodsId] = it
          })
        })
        this.$set(this, 'allRewardCountMap', allRewardCountMap)
      })
    },
    queryInviteActivityStatistics () {
      if (this.formData.dateRange) {
        const [startDate, endDate] = this.formData.dateRange
        this.formData.startDate = startDate
        this.formData.endDate = endDate
      } else {
        delete this.formData.startDate
        delete this.formData.endDate
      }
      getInviteActivityStatistics({ ...this.formData })
        .then(res => {
          if (res.code === 200) {
            this.statisticsInfo = res.data
          }
        })
    },
    getAllChannelType () {
      getAllChannelType({ activityType: 2 })
        .then(res => {
          if (res.code === 200) {
            this.promotionChannelList = res.data.list || []
            this.promotionChannelMap = this.promotionChannelList.reduce((map, item) => {
              map[item.id] = item
              return map
            }, {})
          }
        })
    },
    queryDataList () {
      this.listInfo.loading = true
      if (this.formData.dateRange) {
        const [startTime, endTime] = this.formData.dateRange
        this.formData.startDate = startTime
        this.formData.endDate = endTime
      } else {
        delete this.formData.startDate
        delete this.formData.endDate
      }
      getInviteActivityList({ ...this.formData })
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.list || []
            this.listInfo.total = res.data.total || 0
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    handleSearch () {
      this.formData.pageNum = 1
      this.queryInviteActivityStatistics()
      this.queryDataList()
    },

    downloadExcel (row) {
      downloadInviteCode({
        id: row.activityDetail.id
      }).then(res => {
        if (res.code === 200) {
          this.downloadFile(res.data.url)
        }
      })
    },
    downloadFile (url) {
      var a = document.createElement('a')
      a.href = url
      a.click()
      window.URL.revokeObjectURL(url)
    },
    tochannelManagement () {
      this.$router.push({
        name: 'channelManagement'
      })
    },
    handleCreatInvitationCode () {
      this.$router.push({
        name: 'InvitationCodeCreate'
      })
    },
    handleViewItem (row) {
      this.$router.push({
        name: 'InvitationList',
        query: {
          id: row.activityDetail.id,
          name: row.activityDetail.activityName,
          total: row.activityDetail.cumulativeRegisteredUsers,
          start: row.activityDetail.startUsedTime,
          end: row.activityDetail.endUsedTime
        }
      })
    },
    handleEditItem (row) {
      this.$router.push({
        name: 'InvitationCodeEdit',
        query: {
          id: row.activityDetail.id
        }
      })
    },
    handleToggleItem (row) {
      if (row.activityDetail.globalStatus !== 2) return false
      const TIPS = '此操作将提前结束活动， 是否继续?'
      this.$confirm(TIPS, '提示', { type: 'warning ' })
        .then(() => {
          this.$set(row, 'loading', true)
          updateInviteActivityStatus({
            id: row.activityDetail.id,
            activityStatus: 2
          }).then(res => {
            if (res.code === 200) {
              this.queryDataList()
            }
          }).finally(() => {
            this.$set(row, 'loading', false)
          })
        })
    },
    handleDeleteItem (row) {
      if (row.activityDetail.globalStatus === 2) {
        this.$message.error('活动进行中, 不可删除')
        return false
      }
      if (row.activityDetail.inviteCode === 'netboom') {
        this.$message.error('官方优惠码, 不可删除')
        return false
      }
      this.$confirm('此操作将删除活动, 是否继续', '提示', { type: 'warning ' })
        .then(() => {
          this.$set(row, 'deleteLoading', true)
          deleteInviteActivity({
            id: row.activityDetail.id
          }).then(res => {
            if (res.code === 200) {
              this.$message.success('删除成功')
              this.handleSearch()
            }
          }).finally(() => {
            this.$set(row, 'deleteLoading', false)
          })
        })
    }
  }
}
</script>

<style lang="less">
  .InvitationCode{
    .el-table__expanded-cell{
      background-color: #FAFCFD;
      padding: 30px 0;
      .table-expand{
        label {
          width: 100px;
          color: #99a9bf;
        }
        .el-form-item{
          margin-bottom: 0;
        }
      }
    }
  }
</style>

<style scoped lang="less">

  .InvitationCode {
    .overview {
      display: flex;
      padding: 20px 0;
      min-width: 880px;
      .items {
        width: 210px;
        height: 100px;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, .12);
        border-radius: 4px;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .num {
          font-size: 26px;
          margin-bottom: 10px;
        }
        .name {
          font-size: 12px;
        }
      }
    }
    .rewardRows {
      margin-bottom: 10px;
    }
  }
</style>
